import React from "react";
import { Navbar } from "../client/pages/navigation/Navbar";
import { Footer } from "../client/pages/footer/Footer";
import { NavigationRoute } from "../routes/NavigationRoute";

const contentStyle: React.CSSProperties = {
  minHeight: "50vh",
};

const Main = () => {
  return (
    <div className="logged-out">
      <Navbar />
      <div className="content" style={contentStyle}>
        <NavigationRoute />
      </div>
      <Footer />
    </div>
  );
};

export default Main;
