export const BlogData = [
  {
    id: "1",
    blogTitle: "build or revamp",
    blogImage: "build-revamp.jpg",
    shortDescrip: `Understanding exactly who your dream customer is, discovering where they're congregating, and throwing out carrots that will grab their attention to pull them into your website is the strategy for the next decade.`,
    firstDescrip: `<h1>The website is your spokesperson.</h1>
    <p>Understanding exactly who your dream customer is, discovering where they're congregating, and throwing out carrots that will grab their attention to pull them into your website is the strategy for the next decade.</p>
    <p>Tell them your brand story and make them an offer and thus experience the increase of web visitors that helps you hit your sales and marketing targets each month!</p>
    <p>We are now living deeper in the digital world, whereby more business shifters are plugging into the digital ecosystem (more seriously during the pandemic) for retail F&B education among others. Data is no longer a commodity but instead a currency for the next decade.<p/>
    <p>The ultimate website connects to growth designers, who are also not web designers whose goal is to make a website look pretty. But instead, the growth designers are constantly hustling on the digital battlefield, have experience in direct response marketing, and understand market sentiment and tensions. After all, a smooth sea doesn't make you a skilled sailor.</p>
    `,
    secondDescrip: `
    <h1>Why you should work with CID’s Team to build or revamp your website.</h1>
    <p>Our websites are designed to drive immediate sales, to generate quality sales, and to create long-term customers.</p>`,
    listTitle: `<h1>What now? I already have a website.</h1>`,
    listContent: [
      `Promote your brand story, by publishing new content, articles, and news.`,
      `Generate leads, Run digital ads to drive traffic to your website.`,
      `Monetize web pages and optimize your web pages into SEO money pages.`,
      `Do nothing, Just leave everything to CID to handle it for you.`,
    ],
    endStatement: ``,
  },
  {
    id: "2",
    blogTitle: "Designing for Social Media",
    blogImage: "social-media.jpg",
    shortDescrip: `Are you considering rebranding your social media presence? Would you like to ensure that you cover all your bases?`,
    firstDescrip: `<h1></h1>
    <h1>Are you considering rebranding your social media presence? Would you like to ensure that you cover all your bases?</h1>
    <p>We have a complete six-step guide to rebranding your business on social media the right way in this blog. To plan a rebranding campaign, you need to understand why you are doing it. What you decide to do will depend on your rebranding reasons. If you don't know why you are rebranding, then the rebrand is likely to fail.</p>
    <p>The goal of rebranding is to reach your audience, not to make yourself feel better. Perhaps you need a brand refresh rather than a rebrand if your social media presence is just getting a little stale.</p>
    <p>When the rebrand comes, it should be decisive. The updated brand should feel confident to you and your audience.</p>
    <p>Rebranding, of course, can be a stressful process for social media teams. Your social media presence needs to be overhauled in every detail, and a lot rides on the outcome. By planning your rebrand campaign meticulously, you can alleviate some of the pressure. The following steps will help break the process down into manageable steps.</p>
    `,
    secondDescrip: `
    <h1>These are some good reasons to rebrand your social media presence:</h1>
    <p>You're launching a new product, line, or season, and you need to update your branding to reflect this. A rebranding of your company as a whole should be reflected in your social media presence.You want to reset public perception of your brand after it has been through a major crisis.</p>
    <p>A new target market will not be attracted to your old branding. You have consistently received negative feedback about your branding and tone of voice on social media.There are too many similarities between you and your competitors.</p>
    <p>You didn't create your brand consciously. In the past, you have just winged it, but now you want a more cohesive approach.</p>`,
    listTitle: `<h1>Below are a few reasons why you shouldn't rebrand:</h1>`,
    listContent: [
      `Everybody else is doing it.`,
      `You're not sure why engagement has been low lately.`,
      `Your last rebrand was 100 years ago, so you feel like you should rebrand.`,
    ],
    endStatement: `<p>We can talk in-depth about your social media rebranding over a cup of coffee.</p>`,
  },
  {
    id: "3",
    blogTitle: "Product Design VS Development",
    blogImage: "product-design.jpg",
    shortDescrip: `The definition of product design describes the process of imagining, creating, and iterating products that solve users’ problems or address specific needs in a given market.`,
    firstDescrip: `
    <h1>Product Design</h1>
    <p>The definition of product design describes the process of imagining, creating, and iterating products that solve users’ problems or address specific needs in a given market.</p>
    <p>The key to successful product design is understanding the end-user customer, the person for whom the product is being created. Product designers attempt to solve real problems for real people by using empathy and knowledge of their prospective customers’ habits, behaviors, frustrations, needs, and wants.</p>
    <p>Ideally, the product design’s execution is so flawless that no one notices; users can intuitively use the product as needed because the product design understood their needs and anticipated their usage.</p>
    <p>Good product design practices thread themselves throughout the entire product lifecycle. Product design is essential in creating the initial user experience and product offering, from pre-ideation user research to concept development to prototyping and usability testing.</p>`,
    secondDescrip: `
    <h1>Product development</h1>
    <p>typically refers to all stages involved in bringing a product from concept or idea through market release and beyond. In other words, product development incorporates a product’s entire journey.</p>`,
    listTitle: `
    <h1>Standard Stages of Progress in Product Development</h1>
    <p>There are many steps to this process, and it’s not the same path for every organization, but these are the most common stages through which products typically progress:</p>`,
    listContent: [
      `Identifying a market need`,
      `Quantifying the opportunity`,
      `Conceptualizing the product`,
      `Validating the solution`,
      `Building the product roadmap`,
      `Releasing the MVP to users`,
      `Continual iteration based on user feedback and strategic goals`,
    ],
    endStatement: `<p>In addition to product design, CID can handle all stages of the product development process. We can help you if you're thinking of developing a product.</p>`,
  },
];

export const BrandingData = [
  {
    brandId: "1",
    blogTitle: "Branding",
    blogImage: "branding.png",
    firstDescrip: `
    <h1>What exactly is branding?</h1>
    <p>Establishing your brand is the first step to showing your image and describing the ethos of your business. Branding for small and large businesses alike can be innovative, intuitive, and effective with us. The branding needs of corporate long-term businesses and small startup companies always differ. CID's designers will work with you to determine what type of branding is most appropriate for your business and audience.</p>`,
    listTitle1: `
    <h1>What we offer under branding:<br/>The brand story </h1>`,
    listContent1: [
      `There is a story behind every brand.`,
      `This is the never-ending story of a brand - its ebbs and flows.`,
      `However, there are moments in a brand's life cycle when a new story can be told-when perceptions can be changed to the brand's advantage.`,
      `At these critical moments, we must craft a new brand story.`,
    ],
    secondDescrip: `
    <h1>What is a Brand Story?</h1>
    <p>As a result of the brand strategy, the brand story reveals the brand's overall intent. Your mission. Your audience. Your unique approach. And most importantly, the tone and manner of the brand. Overall, a brand story serves to define your brand for both internal and external audiences. In addition, the brand story should evoke an emotional reaction from the target audience. Should the audience feel inspired? Should they take action? Should they feel joy? Tell your brand's story and you will deliver your brand.</p>
    <p>In today's marketplace, there may be triggers to reposition your brand, but your brand story is 
    never static. We can build the platform, the tactics, and the content to continuously create a compelling brand experience.</p>`,
    listTitle2: `
    <h1><br/>Brand Identity and Design</h1>`,
    listContent2: [
      `Congratulations. You have a brand name and your company is registered.`,
      `Now you need to bring it to life with graphics. What’s the right typeface? Should you include a logo mark? Uppercase or lowercase? Bold or subtle colors?.`,
    ],
    thirdDescrip: `
    <h1><br/>Brand Identity Design</h1>
    <p>We always start with brand strategy at CID. Our team also plans to hold a tailored meeting to discuss brand identities within and outside of your category when considering a brand design. For example, do you like how Nike or Adidas market their products? Audi or Mercedes? Microsoft or Apple? We will gain meaningful insight from this discussion so that we can customize a brand design that not only meets your strategy but also resonates with your sensibilities.
    Starting with the logo design, we create a full brand identity that includes elements like graphic elements, imagery style, and more. We document it all in a style guide that defines how to use it all.
    </p>`,
    fourthDescrip: `
    <h1>Brand Strategy<br/>What is a Brand Strategy?</h1>
    <p>The brand strategy of an organization is a collection of words and phrases that together formally express the essence of the organization. The business plan should include your history as well as your future, your products, and your services, your customers and prospects, the attributes of your brand, your place in the market, and, most importantly, how you bring it all together.</p>
    <p>We need to dig in deep to capture and articulate all of this clearly. Clearly and thoroughly. At all levels. There is no way to avoid the process, and there is no way to minimize the effort. This form of rigorous brand development yields the most authentic brand strategy, so we take it very seriously. It's the only kind of brand strategy we deliver.</p>
    <p>In addition to the value proposition, positioning statement, brand idea, and brand story, we will deliver all the formal brand strategy components. We also include brand architecture as part of our brand strategy services. We see brand architecture as something of an afterthought - a lost art. By contrast, this brand strategy component can help constituents understand your company and make it more appealing.</p>`,
    fifthDescrip: `
    <h1>Brand experience<br/>What is Brand Experience?</h1>
    <p>The Brand Experience is the Brand, meaning that a brand is the sum of all the interactions with its customers. As a brand experience agency, we help brands define their brand experience, and we audit, analyze, align, and implement all of its online and offline touchpoints in order to create more intuitive, meaningful, and productive consumer engagement.</p>
    <p>The common denominator in any brand experience is feeling - how do you feel when you interact with a brand? Whatever the right answer for your brand, the feeling must be consistent across all touchpoints. Whether seeing an ad, visiting your website, or communicating with All touchpoints - from the brand voice to the imagery - both in customer service and interacting with the product or service should feel the same.</p>
    <p>Our company was built in 2018 around the concept of brand experience, and it is the lens through which we view all assignments.</p>
    `,
  },
];
