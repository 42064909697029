import { NavLink } from "react-router-dom";

export const Navbar = () => {
  return (
    <div className="navigation-page">
      <div className="menu-toggle" data-uk-toggle="target: #offcanvas-navbar">
        <div className="icon"></div>
      </div>
      <div id="offcanvas-navbar" data-uk-offcanvas="overlay: true">
        <div className="uk-offcanvas-bar uk-flex uk-flex-column">
          <ul className="uk-nav uk-nav-primary uk-margin-auto-vertical nav-items">
            <div className="contant-info">
              <div>
                <a href="mailto:etc@trycid.com">etc@trycid.com</a>
              </div>
              <div>
                <a href="tel:+264 81 325 5089">+264 81 325 5089</a>
              </div>
            </div>
            <div className="menu-links">
              <ul data-uk-toggle="#offcanvas-navbar">
                <li className="nav-item uk-active">
                  <NavLink to={"/"}> Home</NavLink>{" "}
                </li>
                <li className="nav-item">
                  <NavLink to={"/about"}> About</NavLink>{" "}
                </li>
                <li className="nav-item">
                  <NavLink to={"/work"}> Work</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to={"/blog"}> Blog</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to={"/contact"}>Contact</NavLink>
                </li>
              </ul>
            </div>
            <div className="social-media">
              <div className="social-link-holder">
                <a href="https://www.instagram.com/creations_industrial_design/">
                  Instagram
                </a>
              </div>
              <div className="social-link-holder">
                <a
                  href="https://www.facebook.com/creatorshub999/"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  Facebook
                </a>
              </div>
            </div>
          </ul>
        </div>
      </div>
      <nav className="container-fluid cnav">
        <div className="row">
          <div className="col">
            <div className="logo-holder">
              <a href="/">
                <img
                  className="logo"
                  src={process.env.PUBLIC_URL + "/assets/image/logo1.png"}
                  alt="CID"
                />
              </a>
            </div>
          </div>
          <div className="col text-right">
            <div className="social-media">
              <div className="social-link-holder">
                <a href="https://www.instagram.com/creations_industrial_design/">
                  Instagram
                </a>
              </div>
              <div className="social-link-holder">
                <a href="https://www.facebook.com/creatorshub999/">Facebook</a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};
