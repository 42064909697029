import { useEffect, useState, Suspense } from "react";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import Main from "./main/Main";
import AOS from "aos";
import "aos/dist/aos.css";
import "./assets/scss/style.scss";

function ScrollToTop(props: any) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return props.children;
}

function App() {
  useEffect(() => {
    AOS.init({
      offset: 80,
      duration: 700,
      once: false,
      easing: "ease",
    });
    AOS.refresh();
  }, []);

  const [loading, setLoading] = useState(true);

  function demoAsyncCall() {
    return new Promise<void>((resolve) => setTimeout(() => resolve(), 2500));
  }

  useEffect(() => {
    demoAsyncCall().then(() => setLoading(false));
  });

  if (loading) return null; // render null when app is not ready

  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <Main />
        <ScrollToTop />
      </Router>
    </div>
  );
}

export default App;

// Small font sise to roboto not bold
// Colors on all pages
// list font not bold
// nav menu icon colors
// button one colored
//
