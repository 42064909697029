import { Route, Routes, Navigate } from "react-router-dom";
// import { Home } from "../client/pages/home/Home";
// import { About } from "../client/pages/about/About";
// import { Blog } from "../client/pages/blog/Blog";
// import { Contact } from "../client/pages/contact/Contact";
// import { Work } from "../client/pages/work/Work";
// import { Project } from "../client/pages/project/Project";
// import { Post } from "../client/pages/post/Post";
import WorkDetail from "../client/pages/work/WorkDetail";
import BlogDetail, { BrandDetail } from "../client/pages/blog/BlogDetail";
import { Suspense, lazy } from "react";
import { LoadingEllipsis } from "../utils/Loading";

const Home = lazy(() => import("../client/pages/home/Home"));
const About = lazy(() => import("../client/pages/about/About"));
const Blog = lazy(() => import("../client/pages/blog/Blog"));
const Contact = lazy(() => import("../client/pages/contact/Contact"));
const Work = lazy(() => import("../client/pages/work/Work"));
const Project = lazy(() => import("../client/pages/project/Project"));
const Post = lazy(() => import("../client/pages/post/Post"));

export const NavigationRoute = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Suspense fallback={<LoadingEllipsis fullWidth={true} />}>
            <Home />
          </Suspense>
        }
      />

      <Route
        path="about"
        element={
          <Suspense fallback={<LoadingEllipsis fullWidth={true} />}>
            <About />
          </Suspense>
        }
      />
      <Route
        path="contact"
        element={
          <Suspense fallback={<LoadingEllipsis fullWidth={true} />}>
            <Contact />
          </Suspense>
        }
      />
      <Route
        path="work"
        element={
          <Suspense fallback={<LoadingEllipsis fullWidth={true} />}>
            <Work />
          </Suspense>
        }
      />
      <Route path="work/:id" element={<WorkDetail />} />
      <Route
        path="blog"
        element={
          <Suspense fallback={<LoadingEllipsis fullWidth={true} />}>
            <Blog />
          </Suspense>
        }
      />
      <Route path="blog/:id" element={<BlogDetail />} />
      <Route path="blog/brand/:brandId" element={<BrandDetail />} />
      <Route
        path="project"
        element={
          <Suspense fallback={<LoadingEllipsis fullWidth={true} />}>
            <Project />
          </Suspense>
        }
      />
      <Route
        path="post"
        element={
          <Suspense fallback={<LoadingEllipsis fullWidth={true} />}>
            <Post />
          </Suspense>
        }
      />
      <Route path="*" element={<Navigate to={"/"} />} />
    </Routes>
  );
};
