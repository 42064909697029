export const Footer = () => {
  return (
    <footer className="container-fluid footer">
      <div className="row">
        <div className="col">
          <div className="lg-text" data-aos="fade-up" data-aos-delay="200">
            <span className="other-color">
              Express your brand essence using design.
            </span>
            <br />
          </div>
          <div className="normal-text">
            <div>
              <p data-aos="fade-in" data-aos-delay="200">
                People often come to us thinking they want a logo, but they
                don't. What they want is a brand. By helping to shape the
                perception of the entire brand experience in the marketplace, we
                get them there, often beyond their expectations.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="row" data-aos="fade-in" data-aos-delay="200">
        <div className="col">
          <div className="contact-info-holder">
            <div className="title">Call us</div>
            <div className="contact-info">
              <a href="tel:+264 81 325 5089">+264 81 325 5089</a>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="contact-info-holder">
            <div className="title">E-mail</div>
            <div className="contact-info">
              <a href="mailto:etc@trycid.com">etc@trycid.com</a>
            </div>
            <div className="social-media">
              <div className="social-link-holder">
                <a href="https://www.instagram.com/creations_industrial_design/">
                  Instagram
                </a>
              </div>
              <div className="social-link-holder">
                <a href="https://www.facebook.com/creatorshub999/">Facebook</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
