interface IItem {
  id: string;
  ItemImage: string;
  clientName: string;
  shortDescrip: string;
  fullDescrip: string;
  website: string;
  sector: string;
  deliverables: string[];
  gallery: string[];
}

interface IWorkProps {
  work: IItem;
}

const WorkDetailItem = (props: IWorkProps) => {
  const { work } = props;
  return (
    <div>
      <header className="container-fluid header">
        <div className="row">
          <div className="col">
            <div className="lg-text" data-aos="fade-up" data-aos-delay="200">
              <span className="other-color">{work.clientName}</span>
            </div>
          </div>
        </div>
      </header>

      <div className="container-fluid project-info">
        <div className="row">
          <div className="col">
            <div className="btn-holder">
              <a href="/work" className="cr-btn primary btn-secondary btn-lg">
                Back
              </a>
            </div>
            <h2>{work.clientName}</h2>
            <div className="lg-text">
              <span>{work.shortDescrip}</span>
            </div>
            <div className="normal-text">
              <p dangerouslySetInnerHTML={{ __html: work.fullDescrip }} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 col-md-3">
            <div className="text-box">
              <div className="title">Client</div>
              <p>{work.clientName}</p>
            </div>
          </div>
          <div className="col-sm-6 col-md-3">
            <div className="text-box">
              <div className="title">Website</div>
              <p>{work.website}</p>
            </div>
          </div>
          <div className="col-sm-6 col-md-3">
            <div className="text-box">
              <div className="title">Sector</div>
              <p>{work.sector}</p>
            </div>
          </div>
          <div className="col-sm-6 col-md-3">
            <div className="text-box">
              <div className="title">Deliverables</div>
              {work.deliverables.map((value, i) => {
                return (
                  <p key={i} dangerouslySetInnerHTML={{ __html: value }} />
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid project-imgs">
        <div className="row">
          <div className="col">
            {work.gallery.map((image, i) => (
              <div className="img-holder">
                <img
                  key={i}
                  src={
                    process.env.PUBLIC_URL + `/assets/image/clients/${image}`
                  }
                  alt=""
                />
              </div>
            ))}
          </div>
        </div>
        <div className="btn-holder">
          <a href="/work" className="cr-btn primary btn-secondary btn-lg">
            Back
          </a>
        </div>
      </div>
    </div>
  );
};

export default WorkDetailItem;
