interface IBlogItemProps {
  id: string;
  blogTitle: string;
  blogImage: string;
  shortDescrip: string;
  firstDescrip: string;
  secondDescrip: string;
  listTitle: string;
  listContent: string[];
  endStatement: string;
}

interface IProps {
  blog: IBlogItemProps;
}
export const BlogDetailItem = (props: IProps) => {
  const { blog } = props;

  return (
    <div>
      <header className="container-fluid header">
        <div className="row">
          <div className="col">
            <div className="lg-text" data-aos="fade-up" data-aos-delay="200">
              <span className="other-color">{blog.blogTitle}</span>
            </div>
          </div>
        </div>
      </header>
      <div className="container-fluid post-section">
        <div className="row">
          <div className="col">
            <div className="img-holder">
              <img
                src={
                  process.env.PUBLIC_URL +
                  `/assets/image/blog/${blog.blogImage}`
                }
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="post-header">
              <div className="text-holder">
                <div className="post-info-holder">
                  <div className="btn-holder">
                    <a
                      href="/blog"
                      className="cr-btn primary btn-secondary btn-lg"
                    >
                      Back
                    </a>
                  </div>
                </div>
                <div dangerouslySetInnerHTML={{ __html: blog.firstDescrip }} />
              </div>
            </div>
            <div className="post-content">
              <div dangerouslySetInnerHTML={{ __html: blog.secondDescrip }} />

              <div dangerouslySetInnerHTML={{ __html: blog.listTitle }} />
              {blog.listContent.map((list, i) => {
                return (
                  <li key={i} dangerouslySetInnerHTML={{ __html: list }} />
                );
              })}
              <div dangerouslySetInnerHTML={{ __html: blog.endStatement }} />
              <div className="post-info-holder">
                <div className="btn-holder">
                  <a
                    href="/blog"
                    className="cr-btn primary btn-secondary btn-lg"
                  >
                    Back
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface IBrandingItemProps {
  brandId: string;
  blogTitle: string;
  blogImage: string;
  firstDescrip: string;
  listTitle1: string;
  listContent1: string[];
  secondDescrip: string;
  listTitle2: string;
  listContent2: string[];
  thirdDescrip: string;
  fourthDescrip: string;
  fifthDescrip: string;
}

interface IBrandProps {
  brand: IBrandingItemProps;
}

export const BrandingDetailItem = (props: IBrandProps) => {
  const { brand } = props;

  return (
    <div>
      <header className="container-fluid header">
        <div className="row">
          <div className="col">
            <div className="lg-text" data-aos="fade-up" data-aos-delay="200">
              <span className="other-color">{brand.blogTitle}</span>
            </div>
          </div>
        </div>
      </header>
      <div className="container-fluid post-section">
        <div className="row">
          <div className="col">
            <div className="img-holder">
              <img
                src={
                  process.env.PUBLIC_URL +
                  `/assets/image/blog/${brand.blogImage}`
                }
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="post-header">
              <div className="text-holder">
                <div className="post-info-holder">
                  <div className="btn-holder">
                    <a
                      href="/blog"
                      className="cr-btn primary btn-secondary btn-lg"
                    >
                      Back
                    </a>
                  </div>
                </div>
                <div dangerouslySetInnerHTML={{ __html: brand.firstDescrip }} />
              </div>
            </div>
            <div className="post-content">
              <div dangerouslySetInnerHTML={{ __html: brand.secondDescrip }} />
              <div dangerouslySetInnerHTML={{ __html: brand.listTitle1 }} />
              {brand.listContent1.map((list, i) => {
                return (
                  <li key={i} dangerouslySetInnerHTML={{ __html: list }} />
                );
              })}
              <div dangerouslySetInnerHTML={{ __html: brand.listTitle2 }} />
              {brand.listContent2.map((list, i) => {
                return (
                  <li key={i} dangerouslySetInnerHTML={{ __html: list }} />
                );
              })}
              <div dangerouslySetInnerHTML={{ __html: brand.thirdDescrip }} />
              <div dangerouslySetInnerHTML={{ __html: brand.fourthDescrip }} />
              <div dangerouslySetInnerHTML={{ __html: brand.fifthDescrip }} />
              <div className="btn-holder">
                <a href="/blog" className="cr-btn primary btn-secondary btn-lg">
                  Back
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

{
  /* <div
              data-uk-grid="masonry: true"
              data-uk-lightbox="animation: fade"
              data-uk-scrollspy="target: img-holder;  cls: uk-animation-slide-bottom; delay: 100"
            >
              <a href={`/assets/image/blog/${brand.blogImage}`}>
                <div className="img-holder">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      `/assets/image/blog/${brand.blogImage}`
                    }
                    alt=""
                  />
                </div>
              </a>
            </div> */
}
