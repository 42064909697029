import {
  BlogDetailItem,
  BrandingDetailItem,
} from "../../components/blog/BlogDetailItem";
import { BlogData, BrandingData } from "../../../data/BlogData";
import { useParams } from "react-router-dom";

const BlogDetail = () => {
  const { id } = useParams();
  const data = BlogData?.filter((blog) => blog.id === id);

  return (
    <div>
      <div>
        <BlogDetailItem key={id} blog={data[0]} />
      </div>
      <div className="container-fluid other-content">
        <div className="row">
          <div className="col">
            <div className="lg-text">
              have a project
              <br />
              for us?
            </div>
            <div className="normal-text">
              <p>
                Fill in the brief and we’ll get back to you as soon as possible.
              </p>
            </div>
            <div className="btn-holder">
              <a href="/contact" className="cr-btn primary ex-padding">
                let’s create
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogDetail;

export const BrandDetail = () => {
  const { brandId } = useParams();
  const data = BrandingData?.filter((brand) => brand.brandId === brandId);

  return (
    <div>
      <div>
        <BrandingDetailItem key={brandId} brand={data[0]} />
      </div>
      <div className="container-fluid other-content">
        <div className="row">
          <div className="col">
            <div className="lg-text">
              have a project
              <br />
              for us?
            </div>
            <div className="normal-text">
              <p>
                Fill in the brief and we’ll get back to you as soon as possible.
              </p>
            </div>
            <div className="btn-holder">
              <a href="/contact" className="cr-btn primary ex-padding">
                let’s create
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
